import omit from 'lodash/omit';
import { BaseApiClient } from '@aph/utilities/BaseApiClient';
import type { RedirectData } from '@aph/utilities/errors/types';
import { appendLeadingSlash, appendTrailingSlash, compareSlugs } from '@aph/utilities/slug';
import { logger } from '~/logging';
import type {
  ArticleReviewResult,
  Brand,
  CampaignResponse,
  Category,
  IArticle,
  IFilter,
  ISorting,
  PageArticleGroup,
} from '../generated/ArticlesClient';
import type { components, paths } from '../generated/api.schema';
import { withFilterQuerySerializer } from './with-filter-query-serializer';

export type GetPageArticleGroupsParams = {
  pageId: string;
  filter?: components['schemas']['FilterOptionReference'][];
  data?: components['schemas']['PageArticleGroupsRequest'];
};

type GetArticlesResourceType = 'category' | 'brand' | 'campaign';

export interface GetArticlesParams {
  byResourceType: GetArticlesResourceType;
  take?: number;
  skip?: number;
  sortBy?: string;
  filterBy?: Array<components['schemas']['FilterOptionReference']>;
}

export type GetFilterAndSortingOptionsParams = Pick<GetArticlesParams, 'byResourceType'>;

export class ArticlesApiClient extends BaseApiClient<paths> {
  /**
   * Retrieves an article by slug
   */
  public async getArticle(params: { slug: string; code?: string; recommendationsInfo?: string }) {
    let articleSlug = params.slug;

    if (params.code) {
      articleSlug += `?code=${params.code}`;
    }

    const { data, error, response } = await this.client.GET('/bff-articles/v{version}/articles', {
      params: {
        query: {
          articleSlug,
        },
        header: {
          'x-recommendations-info': params?.recommendationsInfo,
        },
        path: {
          version: '1',
        },
      },
    });

    if (error) {
      logger.error({ ...error, url: response.url }, `getArticle: ${error.title}.`);
      throw error;
    }

    if (data?.articleUrlSegment && !compareSlugs(data.articleUrlSegment, articleSlug)) {
      logger.debug(
        `Redirecting "/produkt/${params.slug}/" to "/produkt/${data.articleUrlSegment}"/`,
      );
      return {
        isRedirect: true,
        location: appendTrailingSlash(
          appendLeadingSlash(['produkt', data.articleUrlSegment].join('/')),
        ),
      } as RedirectData;
    }

    if (params.recommendationsInfo && data) {
      // a bit of a hack to get the trackingId into the article object since it's not part of the article api response
      data.trackingId = params.recommendationsInfo;
    }

    // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
    return data as unknown as IArticle;
  }

  /**
   * Retrieves articles by code based type of resource (e.g. Category, Brand, Campaign)
   *
   */
  public async getArticlesByCode(code: string, params: GetArticlesParams) {
    if (params.byResourceType === 'category') {
      const { data, error, response } = await this.client.GET(
        '/bff-articles/v{version}/categories/{code}',
        {
          querySerializer: withFilterQuerySerializer,
          params: {
            query: {
              'Pagination.Take': params.take,
              'Pagination.Skip': params.skip,
              'Sorting.Selected': params.sortBy,
              Filter: params.filterBy,
            },
            path: {
              version: '1',
              code,
            },
          },
        },
      );

      if (error) {
        logger.error({ ...error, url: response.url }, `getArticlesByCategory: ${error.title}.`);
        throw error;
      }
      // TEMP as Category
      return omit(data, ['sorting', 'filter']) as Category;
    }

    if (params.byResourceType === 'brand') {
      const { data, error, response } = await this.client.GET(
        '/bff-articles/v{version}/brands/{code}',
        {
          querySerializer: withFilterQuerySerializer,
          params: {
            query: {
              'Pagination.Take': params.take,
              'Pagination.Skip': params.skip,
              'Sorting.Selected': params.sortBy,
              Filter: params.filterBy,
            },
            path: {
              version: '1',
              code,
            },
          },
        },
      );

      if (error) {
        logger.error({ ...error, url: response.url }, `getArticlesByBrand: ${error.title}.`);
        throw error;
      }
      // TEMP as Brand
      return omit(data, ['sorting', 'filter']) as Brand;
    }

    if (params.byResourceType === 'campaign') {
      const { data, error, response } = await this.client.GET(
        '/bff-articles/v{version}/campaigns/{code}',
        {
          querySerializer: withFilterQuerySerializer,
          params: {
            query: {
              'Pagination.Take': params.take,
              'Pagination.Skip': params.skip,
              'Sorting.Selected': params.sortBy,
            },
            path: {
              version: '1',
              code,
            },
          },
        },
      );

      if (error) {
        logger.error({ ...error, url: response.url }, `getArticlesByCampaign: ${error.title}.`);
        throw error;
      }
      // TEMP as Campaign
      return omit(data, ['sorting', 'filter']) as CampaignResponse;
    }

    throw new Error(`Invalid type: ${params.byResourceType}`);
  }

  /**
   * Retrieves articles by slug based type of resource (e.g. Category, Brand, Campaign)
   *
   */
  public async getArticlesBySlug(slug: string, params: GetArticlesParams) {
    if (params.byResourceType === 'campaign') {
      const { data, error, response } = await this.client.GET(
        '/bff-articles/v{version}/campaigns',
        {
          querySerializer: withFilterQuerySerializer,
          params: {
            query: {
              type: 'Public',
              slug,
              'Pagination.Take': params.take,
              'Pagination.Skip': params.skip,
              'Sorting.Selected': params.sortBy,
            },
            path: {
              version: '1',
            },
          },
        },
      );

      if (error) {
        logger.error({ ...error, url: response.url }, `getArticlesByCampaign: ${error.title}.`);
        throw error;
      }
      // TEMP as Campaign
      return omit(data, ['sorting', 'filter']) as CampaignResponse;
    }

    if (params.byResourceType === 'category') {
      const { data, error, response } = await this.client.GET(
        '/bff-articles/v{version}/categories',
        {
          querySerializer: withFilterQuerySerializer,
          params: {
            query: {
              slug,
              'Pagination.Take': params.take,
              'Pagination.Skip': params.skip,
              'Sorting.Selected': params.sortBy,
              Filter: params.filterBy,
            },
            path: {
              version: '1',
            },
          },
        },
      );

      if (error) {
        logger.error({ ...error, url: response.url }, `getArticlesByCategory: ${error.title}.`);
        throw error;
      }
      // TEMP as Category
      return data as Category;
    }

    if (params.byResourceType === 'brand') {
      const { data, error, response } = await this.client.GET('/bff-articles/v{version}/brands', {
        querySerializer: withFilterQuerySerializer,
        params: {
          query: {
            slug,
            'Pagination.Take': params.take,
            'Pagination.Skip': params.skip,
            'Sorting.Selected': params.sortBy,
            Filter: params.filterBy,
          },
          path: {
            version: '1',
          },
        },
      });

      if (error) {
        logger.error({ ...error, url: response.url }, `getArticlesByBrand: ${error.title}.`);
        throw error;
      }
      // TEMP as Brand
      return data as Brand;
    }

    throw new Error(`Invalid type: ${params.byResourceType}`);
  }

  public async getArticlesByCodes(codes: string[]) {
    const { data, error, response } = await this.client.GET(
      '/bff-articles/v{version}/article-references',
      {
        querySerializer: withFilterQuerySerializer,
        params: {
          query: {
            code: codes,
          },
          path: {
            version: '1',
          },
        },
      },
    );

    if (error) {
      logger.error({ ...error, url: response.url }, `getArticlesByCodes: ${error.title}.`);
      throw error;
    }

    return data as unknown as IArticle[];
  }

  public async getLatestArticles(params: Omit<GetArticlesParams, 'byResourceType'>) {
    const { data, error, response } = await this.client.GET(
      '/bff-articles/v{version}/latest-articles',
      {
        querySerializer: withFilterQuerySerializer,
        params: {
          query: {
            includeRecommendations: false,
            'Pagination.Take': params.take,
            'Sorting.Selected': params.sortBy,
            'Pagination.Skip': params.skip,
            Filter: params.filterBy,
          },
          path: {
            version: '1',
          },
        },
      },
    );

    if (error) {
      logger.error({ ...error, url: response.url }, `getLatestArticles: ${error.title}.`);
      throw error;
    }

    return data as unknown as {
      articles: {
        results: IArticle[];
        resultsCount: number;
      };
    };
  }

  public async getLatestArticlesRecommendations() {
    const { data, error, response } = await this.client.GET(
      '/bff-articles/v{version}/latest-articles',
      {
        params: {
          query: {
            'Pagination.Take': 1,
            includeRecommendations: true,
          },
          path: {
            version: '1',
          },
        },
      },
    );

    if (error) {
      logger.error(
        { ...error, url: response.url },
        `getLatestArticlesRecommendations: ${error.title}.`,
      );
      throw error;
    }

    return data.recommendations as unknown as IArticle[];
  }

  public getLatestArticlesSortingOptions() {
    return this.client
      .GET('/bff-articles/v{version}/latest-articles', {
        querySerializer: withFilterQuerySerializer,
        params: {
          query: {
            'Pagination.Take': 1,
            includeRecommendations: false,
          },
          path: {
            version: '1',
          },
        },
      })
      .then((res) => {
        if (res.data) {
          return {
            // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
            sorting: res.data?.articles?.sorting as ISorting,
            filtering: res.data?.articles?.filter as IFilter,
          };
        }
        return null;
      });
  }

  public async getArticleReviews(params: { articleCode: string; take?: number; skip?: number }) {
    const { data, error, response } = await this.client.GET(
      '/bff-articles/v{version}/articles/{articleCode}/reviews',
      {
        params: {
          path: {
            version: '1',
            articleCode: params.articleCode,
          },
          query: {
            Take: params.take,
            Skip: params.skip,
          },
        },
      },
    );

    if (error) {
      logger.error({ ...error, url: response.url }, `getArticleReviews: ${error.title}.`);
    }

    // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE
    return data as ArticleReviewResult;
  }

  /**
   * Retrieves the filter and sorting options by code based type of resource (e.g. Category, Brand, Campaign)
   */
  public getFilterAndSortingOptionsByCode(code: string, params: GetFilterAndSortingOptionsParams) {
    if (params.byResourceType === 'category') {
      return this.client
        .GET('/bff-articles/v{version}/categories/{code}', {
          params: {
            query: {
              'Pagination.Take': 1,
            },
            path: {
              version: '1',
              code,
            },
          },
        })
        .then((res) => {
          if (res.data) {
            return {
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              sorting: res.data?.articles?.sorting as ISorting,
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              filtering: res.data?.articles?.filter as IFilter,
            };
          }

          return null;
        });
    }

    if (params.byResourceType === 'brand') {
      return this.client
        .GET('/bff-articles/v{version}/brands/{code}', {
          params: {
            query: {
              'Pagination.Take': 1,
            },
            path: {
              version: '1',
              code,
            },
          },
        })
        .then((res) => {
          if (res.data) {
            return {
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              sorting: res.data?.articles?.sorting as ISorting,
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              filtering: res.data?.articles?.filter as IFilter,
            };
          }

          return null;
        });
    }

    if (params.byResourceType === 'campaign') {
      return this.client
        .GET('/bff-articles/v{version}/campaigns/{code}', {
          params: {
            query: {
              'Pagination.Take': 1,
            },
            path: {
              version: '1',
              code,
            },
          },
        })
        .then((res) => {
          if (res.data) {
            return {
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              sorting: res.data?.articles?.sorting as ISorting,
            };
          }

          return null;
        });
    }

    throw new Error(`Invalid type: ${params.byResourceType}`);
  }

  /**
   * Retrieves the filter and sorting options by slug based type of resource (e.g. Category, Brand, Campaign)
   */
  public getFilterAndSortingOptionsBySlug(slug: string, params: GetFilterAndSortingOptionsParams) {
    if (params.byResourceType === 'category') {
      return this.client
        .GET('/bff-articles/v{version}/categories', {
          params: {
            query: {
              'Pagination.Take': 1,
              slug,
            },
            path: {
              version: '1',
            },
          },
        })
        .then((res) => {
          if (res.data) {
            return {
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              sorting: res.data?.articles?.sorting as ISorting,
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              filtering: res.data?.articles?.filter as IFilter,
            };
          }

          return null;
        });
    }

    if (params.byResourceType === 'brand') {
      return this.client
        .GET('/bff-articles/v{version}/brands', {
          params: {
            query: {
              'Pagination.Take': 1,
              slug,
            },
            path: {
              version: '1',
            },
          },
        })
        .then((res) => {
          if (res.data) {
            return {
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              sorting: res.data?.articles?.sorting as ISorting,
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              filtering: res.data?.articles?.filter as IFilter,
            };
          }

          return null;
        });
    }

    if (params.byResourceType === 'campaign') {
      return this.client
        .GET('/bff-articles/v{version}/campaigns', {
          params: {
            query: {
              'Pagination.Take': 1,
              type: 'Public',
              slug,
            },
            path: {
              version: '1',
            },
          },
        })
        .then((res) => {
          if (res.data) {
            return {
              // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
              sorting: res.data?.articles?.sorting as ISorting,
            };
          }

          return null;
        });
    }

    throw new Error(`Invalid type: ${params.byResourceType}`);
  }

  /**
   * Retrieves eSales panels for a specific page (e.g. startpage, product-page etc)
   */
  public async getPageArticleGroups(params: GetPageArticleGroupsParams) {
    if (params.data) {
      // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
      return this.getPageArticleGroupsPOST(params) as unknown as PageArticleGroup;
    }

    // TEMPORARY RETURN THE OLD TYPE TO AVOID TYPE ERRORS IN THE CODE WHILE WE TEST THIS
    return this.getPageArticleGroupsGET(params) as unknown as PageArticleGroup;
  }

  private async getPageArticleGroupsGET(params: Omit<GetPageArticleGroupsParams, 'data'>) {
    const { data, error, response } = await this.client.GET(
      '/bff-articles/v{version}/page-article-groups/{pageId}',
      {
        querySerializer: withFilterQuerySerializer,
        params: {
          path: {
            version: '1',
            pageId: params.pageId,
          },
          query: {
            filter: params.filter,
          },
        },
      },
    );

    if (error) {
      logger.error({ ...error, url: response.url }, `getPageArticleGroupsGET: ${error.title}.`);
      // graceful fallback (should we throw an error instead??)
      return [];
    }

    return data;
  }

  private async getPageArticleGroupsPOST(params: GetPageArticleGroupsParams) {
    const { data, error, response } = await this.client.POST(
      '/bff-articles/v{version}/page-article-groups/{pageId}',
      {
        querySerializer: withFilterQuerySerializer,
        params: {
          path: {
            version: '1',
            pageId: params.pageId,
          },
          query: {
            filter: params.filter,
          },
        },
        body: params.data,
      },
    );

    if (error) {
      logger.error({ ...error, url: response.url }, `getPageArticleGroupsPOST: ${error.title}.`);
      // graceful fallback (should we throw an error instead??)
      return [];
    }
    return data;
  }
}
